import { auth } from './auth';
import { mylearnn } from './mylearnn';
import { engagement } from './engagement';
import { configuration } from './configuration';
import { player } from './player';
import { webinar } from './webinar';
import { home } from './home';
import { account } from './account';
import { comingcontents } from './comingcontents';
import { quiz } from './quiz';
import { profile } from './profile';
import { search } from './search';
import { smartSearch } from './smart-search';
import { singlecontent } from './singlecontent';
import { feedback } from './feedback';
import { note } from './note';
import { course } from './course';
import { getApolloClient, getCmsApolloClient } from './apollo';
import { recommender } from './recommender';
import { explore } from './explore';
import { fast } from './fast';
import { paths } from './paths';
import getSearchClient from './meilisearch';
import getSearchClients from './orama';
import { billing } from './billing';
const initialize = async (options, API_URL, RECOMMENDER_URL, QUIZ_URL, CONFIG_URL, ENGAGEMENT_URL, SEARCH_URL, SearchApiKey, token, QuizApiKey, ConfigApiKey, ENGAGEMENT_GRAPH_URL, ENGAGEMENT_GRAPH_KEY, KEYCLOAK_REALM_URL, CLIENT_ID, CLIENT_SECRET, CMS_API_TOKEN, IDENTITY_API_URL, CHECKOUT_API_URL, PROFILE_API_URL, PROFILE_REST_API_URL, PROFILE_API_KEY, MY_API_URL, CMS_CONFIG_API_URL, ORAMA_COURSES_URL, oramaCoursesApiKey, ORAMA_LESSONS_URL, oramaLessonsApiKey, ORAMA_PATHS_URL, oramaPathsApiKey) => {
    const { client: cmsClient, refreshToken, getToken, } = await getCmsApolloClient(API_URL, CMS_CONFIG_API_URL, CMS_API_TOKEN, token, options.cache?.persistentStorage);
    const { client: quizClient, refreshToken: refreshQuizToken } = getApolloClient(QUIZ_URL, token, QuizApiKey);
    const { client: configCLient, refreshToken: refreshConfigToken } = getApolloClient(CONFIG_URL, token, ConfigApiKey);
    const { client: engagementClient, refreshToken: refreshEngagementToken } = getApolloClient(ENGAGEMENT_GRAPH_URL, token, ENGAGEMENT_GRAPH_KEY);
    const { client: profileClient, refreshToken: refreshProfileToken } = getApolloClient(PROFILE_API_URL, token, PROFILE_API_KEY);
    const authFactory = auth(KEYCLOAK_REALM_URL, CLIENT_ID, CLIENT_SECRET, IDENTITY_API_URL, CHECKOUT_API_URL, cmsClient, t => {
        refreshToken(t);
        refreshQuizToken(t);
        refreshConfigToken(t);
        refreshEngagementToken(t);
        refreshProfileToken(t);
    }, () => {
        refreshToken();
        refreshQuizToken();
        refreshConfigToken();
        refreshProfileToken();
    }, getToken);
    const { client: searchClient } = getSearchClient(SEARCH_URL, SearchApiKey);
    const oramaClient = getSearchClients(ORAMA_COURSES_URL, ORAMA_LESSONS_URL, ORAMA_PATHS_URL, oramaCoursesApiKey, oramaLessonsApiKey, oramaPathsApiKey);
    const configurationFactory = configuration(cmsClient, configCLient, authFactory);
    const courseFactory = course(cmsClient);
    const playerFactory = player(ENGAGEMENT_URL, cmsClient, getToken, courseFactory.getCourseBaseByLessonId);
    const myLearnnFactory = mylearnn(cmsClient);
    const engagementFactory = engagement(ENGAGEMENT_URL, getToken, courseFactory);
    const recommenderFactory = recommender(RECOMMENDER_URL, getToken);
    const quizFactory = quiz(quizClient, cmsClient, configurationFactory, engagementFactory);
    const profileFactory = profile(profileClient, PROFILE_REST_API_URL, getToken);
    const pathsFactory = paths({
        client: cmsClient,
    });
    const smartSearchFactory = smartSearch(searchClient, oramaClient, engagementFactory);
    return {
        auth: authFactory,
        mylearnn: myLearnnFactory,
        configuration: configurationFactory,
        note: note(engagementClient, playerFactory),
        recommender: recommenderFactory,
        engagement: engagementFactory,
        player: playerFactory,
        home: home(cmsClient, engagementFactory, recommenderFactory, quizFactory, courseFactory, configurationFactory, playerFactory, profileFactory, pathsFactory),
        explore: explore(cmsClient, engagementFactory, recommenderFactory, quizFactory, courseFactory, configurationFactory, playerFactory, profileFactory, pathsFactory),
        fast: fast(cmsClient, engagementFactory, recommenderFactory, quizFactory, courseFactory, configurationFactory, playerFactory, profileFactory, pathsFactory),
        account: account(IDENTITY_API_URL, KEYCLOAK_REALM_URL, MY_API_URL, cmsClient, getToken),
        comingcontents: comingcontents(cmsClient),
        search: search(cmsClient),
        webinar: webinar(cmsClient),
        singlecontent: singlecontent(cmsClient),
        feedback: feedback(engagementClient),
        course: courseFactory,
        quiz: quizFactory,
        profile: profileFactory,
        paths: pathsFactory,
        smartSearch: smartSearchFactory,
        billing: billing(MY_API_URL, getToken),
    };
};
export const API = {
    initialize: initialize,
};
