import { gql } from '@apollo/client';
import { restClient } from './../rest';
import { extractThumbUrlFromImage, extractAudioUrlFromAwsMedia, extractVideoUrlFromAwsMedia, getLessonType, parseVtt, normalizeGraphqlResponse, calculateLessonDuration, } from '../utils';
export const player = (API_URL, client, getToken, getCourseByLessonId) => {
    const getLesson = async (lessonId) => {
        if (!getToken()) {
            throw new Error(`Authentication error`);
        }
        const result = await client
            .query({
            query: LESSON_BY_KEY,
            variables: { lessonId },
        })
            .then(normalizeGraphqlResponse);
        const lessonResult = result.data?.lesson;
        if (!lessonResult) {
            throw new Error(`lesson ${lessonId} not found`);
        }
        const lesson = {
            id: lessonResult.id,
            title: lessonResult.title,
            duration: lessonResult.duration,
            description: lessonResult.description,
            circlePostUrl: lessonResult.circlePostUrl,
            subtitles: lessonResult.awsMedia && lessonResult.awsMedia.subtitles
                ? parseVtt(lessonResult.awsMedia.subtitles?.trim())
                : [],
            transcript: lessonResult.awsMedia && lessonResult.awsMedia.transcript
                ? lessonResult.awsMedia.transcript
                : null,
            imageUrl: extractThumbUrlFromImage(lessonResult.cover, 'large') ?? undefined,
            videoUrl: extractVideoUrlFromAwsMedia(lessonResult.awsMedia),
            audioUrl: extractAudioUrlFromAwsMedia(lessonResult.awsMedia),
            contentType: getLessonType(lessonResult),
            cookies: {
                signedCookies: lessonResult.awsMedia && lessonResult.awsMedia.aws
                    ? lessonResult.awsMedia.aws.signedCookies
                    : undefined,
                cookieExpires: lessonResult.awsMedia && lessonResult.awsMedia.aws
                    ? lessonResult.awsMedia.aws.cookieExpires
                    : undefined,
            },
            free: lessonResult.free ?? false,
            tags: lessonResult.tags ?? [],
        };
        return lesson;
    };
    const getLessonBase = (getCourseByLessonId) => async (lessonId) => {
        if (!getToken()) {
            throw new Error(`Authentication error`);
        }
        const result = await client
            .query({
            query: LESSON_BASE_BY_KEY,
            variables: { lessonId },
        })
            .then(normalizeGraphqlResponse);
        const l = result.data?.lesson;
        let cover = extractThumbUrlFromImage(l.cover, 'thumbnail') ?? undefined;
        const courseData = await getCourseByLessonId(l.id);
        const courseCover = courseData.coverImage;
        return {
            id: l.id,
            title: l.title,
            duration: calculateLessonDuration(l.duration),
            durationSeconds: l.duration,
            coverImageUrl: cover ?? courseCover,
            contentType: getLessonType(l),
            free: l.free ?? false,
            authors: courseData.authors,
            courseTitle: courseData.shortTitle ?? courseData.title,
            tags: l.tags ?? [],
        };
    };
    const getLessonsBase = (getCourseByLessonId) => async (query) => {
        if (!getToken()) {
            throw new Error(`Authentication error`);
        }
        const result = await client
            .query({
            query: LESSONS_BASE_QUERY,
            variables: {
                tagsId: query.tagsId,
                limit: query.limit ? query.limit * 40 : null,
            },
        })
            .then(normalizeGraphqlResponse);
        const response = result.data?.lessons;
        const lessons = [];
        for (const l of response) {
            let cover = extractThumbUrlFromImage(l.cover, 'thumbnail') ?? undefined;
            try {
                const courseData = await getCourseByLessonId(l.id);
                if (!courseData.draft) {
                    const courseCover = courseData.coverImage;
                    lessons.push({
                        id: l.id,
                        title: l.title,
                        duration: calculateLessonDuration(l.duration),
                        durationSeconds: l.duration,
                        coverImageUrl: cover ?? courseCover,
                        contentType: getLessonType(l),
                        free: l.free ?? false,
                        authors: courseData.authors,
                        courseTitle: courseData.shortTitle ?? courseData.title,
                        tags: l.tags ?? [],
                    });
                    if (query.limit && lessons.length === query.limit)
                        return lessons;
                }
            }
            catch (e) { }
        }
        return lessons.slice(0, query.limit);
    };
    const getLessonPreview = async (lessonId) => {
        if (!getToken()) {
            throw new Error(`Authentication error`);
        }
        const result = await client
            .query({
            query: LESSON_PREVIEW,
            variables: { lessonId },
        })
            .then(normalizeGraphqlResponse);
        const lessonResult = result.data?.lesson;
        if (!lessonResult) {
            throw new Error(`lesson ${lessonId} not found`);
        }
        const lesson = {
            id: lessonResult.id,
            title: lessonResult.title,
            duration: lessonResult.duration,
            description: lessonResult.description,
            imageUrl: extractThumbUrlFromImage(lessonResult.cover, 'large') ?? undefined,
            free: lessonResult.free ?? false,
        };
        const engagement = await restClient(getToken()).jsonGet(API_URL, `/engagement/lessons/${lessonId}`);
        return {
            ...lesson,
            imageUrl: lesson.imageUrl ?? engagement?.courseSummary?.course_cover_image_url ?? '',
        };
    };
    return {
        getLesson: getLesson,
        getLessonBase: getLessonBase(getCourseByLessonId),
        getLessonsBase: getLessonsBase(getCourseByLessonId),
        getPreview: getLessonPreview,
    };
};
const LESSON_BY_KEY = gql `
  query lessonByKey($lessonId: ID!) {
    lesson(id: $lessonId) {
      data {
        id
        attributes {
          title
          free
          duration
          description
          defaultMedia
          circlePostUrl
          tags {
            data {
              id
              attributes {
                name
              }
            }
          }
          awsMedia {
            data {
              id
              attributes {
                aws
                subtitles
                transcript
              }
            }
          }
          cover {
            data {
              id
              attributes {
                formats
              }
            }
          }
        }
      }
    }
  }
`;
const LESSONS_BASE_QUERY = gql `
  query lessonsBaseQuery($limit: Int, $tagsId: [ID]) {
    lessons(
      sort: "createdAt:desc"
      filters: { tags: { id: { in: $tagsId } }, draft: { eq: false } }
      pagination: { pageSize: $limit }
    ) {
      data {
        id
        attributes {
          title
          free
          duration
          description
          defaultMedia
          tags {
            data {
              id
              attributes {
                name
              }
            }
          }
          cover {
            data {
              id
              attributes {
                formats
              }
            }
          }
        }
      }
    }
  }
`;
const LESSON_BASE_BY_KEY = gql `
  query lessonByKey($lessonId: ID!) {
    lesson(id: $lessonId) {
      data {
        id
        attributes {
          title
          free
          duration
          description
          defaultMedia
          circlePostUrl
          tags {
            data {
              id
              attributes {
                name
              }
            }
          }
          cover {
            data {
              id
              attributes {
                formats
              }
            }
          }
        }
      }
    }
  }
`;
const LESSON_PREVIEW = gql `
  query lessonPreview($lessonId: ID!) {
    lesson(id: $lessonId) {
      data {
        id
        attributes {
          title
          free
          duration
          cover {
            data {
              id
              attributes {
                formats
              }
            }
          }
        }
      }
    }
  }
`;
