import { gql } from '@apollo/client';
import { extractThumbUrlFromImage, extractCourseAuthorAndProfession, calculateLessonDuration, hideDraft, getLessonType, extractVideoUrlFromAwsMedia, normalizeGraphqlResponse, DEFAULT_WALL_LIMIT, } from '../utils';
export const course = (client) => {
    const getCourse = async (courseId) => {
        const courseResult = await client
            .query({
            query: COURSE_INFO_BY_KEY,
            variables: { courseId },
        })
            .then(normalizeGraphqlResponse);
        const course = courseResult.data?.course;
        if (!course) {
            throw new Error(`course ${courseId} not found`);
        }
        const modulesId = course.moduleCourses.map((m) => m.id);
        const modulesResult = await client
            .query({
            query: MODULES_BY_KEYS,
            variables: { ids: modulesId },
        })
            .then(normalizeGraphqlResponse);
        const modules = modulesResult.data?.moduleCourses;
        if (!modules) {
            throw new Error(`modules of course ${courseId} not found`);
        }
        return toCourse(course, modules);
    };
    const getCourseByLessonId = async (lessonId) => {
        const courseResult = await client
            .query({
            query: COURSE_INFO_BY_LESSON_ID,
            variables: { lessonId },
        })
            .then(normalizeGraphqlResponse);
        const course = courseResult.data?.courses.length > 0 ? courseResult.data?.courses[0] : null;
        if (!course) {
            throw new Error(`course by lesson ${lessonId} not found`);
        }
        const modulesId = course.moduleCourses.map((m) => m.id);
        const modulesResult = await client
            .query({
            query: MODULES_BY_KEYS,
            variables: { ids: modulesId },
        })
            .then(normalizeGraphqlResponse);
        const modules = modulesResult.data?.moduleCourses;
        if (!modules) {
            throw new Error(`modules of course ${lessonId} not found`);
        }
        return toCourse(course, modules);
    };
    const getCourseBaseByLessonId = async (lessonId) => {
        const courseResult = await client
            .query({
            query: COURSE_BASE_INFO_BY_LESSON_ID,
            variables: { lessonId },
        })
            .then(normalizeGraphqlResponse);
        const course = courseResult.data?.courses.length > 0 ? courseResult.data?.courses[0] : null;
        if (!course) {
            throw new Error(`course by lesson ${lessonId} not found`);
        }
        return toCourseBaseInfo(course);
    };
    const toCourse = (c, modules) => ({
        ...toCourseBaseInfo(c),
        modules: modules.map(toModule),
    });
    const toModule = (m) => {
        return {
            id: m.id,
            title: m.title,
            free: m.free ?? false,
            lessons: m.lessons
                .filter(hideDraft)
                .sort((x, y) => x.label >= y.label)
                .map(toLesson),
        };
    };
    const toLesson = (l) => {
        return {
            id: l.id,
            title: l.title,
            duration: calculateLessonDuration(l.duration),
            durationSeconds: l.duration,
            coverImageUrl: extractThumbUrlFromImage(l.cover, 'thumbnail') ?? undefined,
            contentType: getLessonType(l),
            free: l.free ?? false,
            tags: l.tags ?? [],
        };
    };
    const getTrailer = async (courseId) => {
        const result = await client
            .query({
            query: COURSE_TRAILER,
            variables: { courseId },
        })
            .then(normalizeGraphqlResponse);
        const course = result.data?.course;
        if (!course) {
            throw new Error(`course ${courseId} not found`);
        }
        return {
            id: course.id,
            title: course.title,
            trailerVideoUrl: extractVideoUrlFromAwsMedia(course.trailerVideo),
            imageUrl: extractThumbUrlFromImage(course.coverImage, 'large') ?? '',
        };
    };
    const getHighlightVideo = async (courseId) => {
        const result = await client
            .query({
            query: COURSE_HIGHLIGHT,
            variables: { courseId },
        })
            .then(normalizeGraphqlResponse);
        const course = result.data?.course;
        if (!course) {
            throw new Error(`course ${courseId} not found`);
        }
        return {
            highlightVideoUrl: extractVideoUrlFromAwsMedia(course.highlightVideo),
            trailerVideoUrl: extractVideoUrlFromAwsMedia(course.trailerVideo),
        };
    };
    const getList = async (type) => {
        const result = await client
            .query({
            query: COURSES_LIST_QUERY,
            variables: { type },
        })
            .then(normalizeGraphqlResponse);
        if (!result) {
            throw new Error(`Cannot load courses`);
        }
        const courses = result.data.courses.map(toCourseBaseInfo);
        return courses;
    };
    const getAll = async () => {
        const result = await client
            .query({
            query: ALL_COURSES_QUERY,
        })
            .then(normalizeGraphqlResponse);
        if (!result) {
            throw new Error(`Cannot load courses`);
        }
        const courses = result.data.courses.map(toCourseBaseInfo);
        return courses;
    };
    const getAllDrafts = async () => {
        const result = await client
            .query({
            query: ALL_DRAFT_COURSES_QUERY,
        })
            .then(normalizeGraphqlResponse);
        if (!result) {
            throw new Error(`Cannot load draft courses`);
        }
        const courses = result.data.courses.map(({ id }) => id);
        return courses;
    };
    const getCoursePreview = async (courseId) => {
        const courseResult = await client
            .query({
            query: COURSE_PREVIEW_BY_KEY,
            variables: { courseId },
        })
            .then(normalizeGraphqlResponse);
        const course = courseResult.data?.course;
        if (!course) {
            throw new Error(`course ${courseId} not found`);
        }
        return toCoursePreview(course);
    };
    const toCoursePreview = (course) => {
        return {
            ...course,
            coverImage: extractThumbUrlFromImage(course?.coverImage, 'large') ?? '',
        };
    };
    const getLatest = async (contentTypeId) => {
        const result = await client
            .query({
            query: LATEST_COURSES_QUERY,
            variables: { contentTypeId },
        })
            .then(normalizeGraphqlResponse);
        if (!result) {
            throw new Error(`Cannot load courses`);
        }
        return result.data.courses.map(toCourseBaseInfo);
    };
    const getCourseBaseInfo = async (courseId) => {
        const courseResult = await client
            .query({
            query: COURSE_BASE_INFO_BY_KEY,
            variables: { courseId },
        })
            .then(normalizeGraphqlResponse);
        const course = courseResult.data?.course;
        if (!course) {
            throw new Error(`course ${courseId} not found`);
        }
        return toCourseBaseInfo(course);
    };
    const getCourseCategories = async () => {
        const categoriesResult = await client
            .query({
            query: CATEGORIES,
        })
            .then(normalizeGraphqlResponse);
        const categories = categoriesResult.data?.categoryCourses;
        if (!categories) {
            throw new Error(`categories not found`);
        }
        return categories;
    };
    const getResources = async (courseId) => {
        const courseResult = await client
            .query({
            query: COURSE_RESOURCES_BY_KEY,
            variables: { courseId },
        })
            .then(normalizeGraphqlResponse);
        const course = courseResult.data?.course;
        if (!course) {
            throw new Error(`course ${courseId} not found`);
        }
        return course.resources;
    };
    return {
        get: getCourse,
        getCourseByLessonId: getCourseByLessonId,
        getCourseBaseByLessonId: getCourseBaseByLessonId,
        getResources: getResources,
        getPreview: getCoursePreview,
        getTrailer: getTrailer,
        getHighlightVideo: getHighlightVideo,
        getList: getList,
        getAll: getAll,
        getAllDrafts: getAllDrafts,
        getLatest: getLatest,
        getCourseBaseInfo: getCourseBaseInfo,
        getCourseCategories: getCourseCategories,
    };
};
export const toCourseBaseInfo = (c) => ({
    id: c.id,
    title: c.title ?? '',
    shortTitle: c.shortTitle,
    description: c.description,
    resources: c.resources,
    youLearn: c.youLearn,
    circlePostUrl: c.circlePostUrl,
    categories: c.categories,
    companyLogo: c.companyLogo && extractThumbUrlFromImage(c.companyLogo, 'small'),
    authors: c.authors.map(extractCourseAuthorAndProfession),
    type: c.type,
    coverImage: extractThumbUrlFromImage(c.coverImage, 'medium') ?? '',
    duration: `${c.totalHours ? c.totalHours + 'h' : ''} ${c.totalMinutes ? c.totalMinutes + 'min' : ''}`,
    lessonsCount: c.lessonsCount,
    trailerVideoUrl: c.trailerVideo && extractVideoUrlFromAwsMedia(c.trailerVideo),
    highlightVideo: c.highlightVideo && extractVideoUrlFromAwsMedia(c.highlightVideo),
    imageUrl: extractThumbUrlFromImage(c.coverImage, 'large') ?? '',
    draft: c.draft ?? false,
});
export const COURSE_BASE_PROPS = `
title
shortTitle
description
resources
youLearn
circlePostUrl
categories {
  data {
    id
    attributes {
      title
    }
  }
}
companyLogo {
  data {
    id
    attributes {
      formats
      url
      urlCdn
    }
  }
}
draft
authors {
  data {
    id
    attributes {
      firstName
      lastName
      profession
      avatar {
        data {
          id
          attributes {
            formats
          }
        }
      }
    }
  }
}
coverImage {
  data {
    id
    attributes {
      formats
    }
  }
}
totalHours
totalMinutes
lessonsCount
type {
  data {
    id
    attributes {
      title
      description
      slug
    }
  }
}
trailerVideo {
  data {
    id
    attributes {
      urlCdn
      url
      aws
    }
  }
}
highlightVideo {
  data {
    id
    attributes {
      urlCdn
      url
      aws
    }
  }
}
`;
const COURSE_BASE_INFO_BY_KEY = gql `
  query courseByKey($courseId: ID!) {
    course(id: $courseId) {
      data {
        id 
        attributes {
          ${COURSE_BASE_PROPS}
        }
      }
    }
  }
`;
const CATEGORIES = gql `
  query categories {
    categoryCourses(pagination: { pageSize: 25 }) {
      data {
        id
        attributes {
          title
        }
      }
    }
  }
`;
const COURSE_RESOURCES_BY_KEY = gql `
  query courseByKey($courseId: ID!) {
    course(id: $courseId) {
      data {
        id
        attributes {
          resources
        }
      }
    }
  }
`;
const COURSE_INFO_BY_KEY = gql `
  query courseByKey($courseId: ID!) {
    course(id: $courseId) {
      data {
        id
        attributes {
          ${COURSE_BASE_PROPS}
          moduleCourses(pagination: {pageSize: ${DEFAULT_WALL_LIMIT}}) {
            data {
              id
              attributes {
                title
              }
            }
          }
        }
      }
    }
  }
`;
const COURSE_INFO_BY_LESSON_ID = gql `
  query courseByLessonId($lessonId: ID!) {
    courses(filters: { moduleCourses: { lessons: { id: { eq: $lessonId } } } }) {
      data {
        id
        attributes {
          title
          totalHours
          totalMinutes
          lessonsCount
          shortTitle
          resources
          circlePostUrl
          coverImage{
            data{
              id
              attributes{
                formats
                url
              }
            }
          }
          authors {
            data {
              id
              attributes {
                firstName
                lastName
                profession
                avatar {
                  data {
                    id
                    attributes {
                      formats
                    }
                  }
                }
              }
            }
          }
          type {
            data {
              id
              attributes {
                title
                description
                slug
              }
            }
          }
          categories {
            data {
              id
              attributes {
                title
              }
            }
          }
          moduleCourses(pagination: {pageSize: ${DEFAULT_WALL_LIMIT}}) {
            data {
              id
              attributes {
                title
                lessons {
                  data {
                    id
                    attributes {
                      title
                      duration
                      cover {
                        data {
                          id
                          attributes {
                            formats
                            url
                          }
                        }
                      }
                      free
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
const COURSE_BASE_INFO_BY_LESSON_ID = gql `
  query courseByLessonId($lessonId: ID!) {
    courses(filters: { moduleCourses: { lessons: { id: { eq: $lessonId } } } }) {
      data {
        id
        attributes {
          title
          totalHours
          totalMinutes
          lessonsCount
          shortTitle
          circlePostUrl
          draft
          coverImage {
            data {
              id
              attributes {
                formats
                url
              }
            }
          }
          authors {
            data {
              id
              attributes {
                firstName
                lastName
                profession
                avatar {
                  data {
                    id
                    attributes {
                      formats
                    }
                  }
                }
              }
            }
          }
          type {
            data {
              id
              attributes {
                title
                description
                slug
              }
            }
          }
          categories {
            data {
              id
              attributes {
                title
              }
            }
          }
        }
      }
    }
  }
`;
const COURSE_PREVIEW_BY_KEY = gql `
  query courseByKey($courseId: ID!) {
    course(id: $courseId) {
      data {
        id
        attributes {
          title
          description

          coverImage {
            data {
              id
              attributes {
                formats
                urlCdn
                url
              }
            }
          }

          categories {
            data {
              id
              attributes {
                title
              }
            }
          }
        }
      }
    }
  }
`;
const MODULES_BY_KEYS = gql `
  query modulesByKeys($ids: [ID]) {
    moduleCourses(filters: { id: { in: $ids } }, pagination: {pageSize: ${DEFAULT_WALL_LIMIT}}) {
      data {
        id
        attributes {
          label
          title
          free
          lessons(filters: { draft: { eq: false } }, pagination: {pageSize: ${DEFAULT_WALL_LIMIT}}) {
            data {
              id
              attributes {
                label
                title
                duration
                draft
                free
                defaultMedia
                tags {
                  data {
                    id
                    attributes {
                      name
                    }
                  }
                }
                cover {
                  data {
                    id
                    attributes {
                      formats
                    }
                  }
                }
                awsMedia {
                  data {
                   id
                    attributes {
                      url
                      aws
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
const COURSE_TRAILER = gql `
  query getCourseTrailer($courseId: ID!) {
    course(id: $courseId) {
      data {
        id
        attributes {
          title
          coverImage {
            data {
              id
              attributes {
                formats
              }
            }
          }
          trailerVideo {
            data {
              id
              attributes {
                url
                aws
              }
            }
          }
        }
      }
    }
  }
`;
const COURSE_HIGHLIGHT = gql `
  query getCourseHighlight($courseId: ID!) {
    course(id: $courseId) {
      data {
        id
        attributes {
          trailerVideo {
            data {
              id
              attributes {
                urlCdn
                url
                aws
              }
            }
          }
          highlightVideo {
            data {
              id
              attributes {
                urlCdn
                url
                aws
              }
            }
          }
        }
      }
    }
  }
`;
export const COURSES_LIST_QUERY = gql `
  query WallCoursesQuery($type: String!) {
    courses(sort: "createdAt:desc", filters: { type: { slug: {eq: $type} }, draft: {eq: false} }, pagination: {pageSize: ${DEFAULT_WALL_LIMIT}}) {
      data {
        id
        attributes {
          ${COURSE_BASE_PROPS}
        }
      }
    }
  }
`;
export const ALL_COURSES_QUERY = gql `
  query WallCoursesQuery {
    courses(sort: "createdAt:desc", filters: { draft: {eq: false} }, pagination: {pageSize: ${DEFAULT_WALL_LIMIT}}) {
      data {
        id
        attributes {
          ${COURSE_BASE_PROPS}
        }
      }
    }
  }
`;
export const ALL_DRAFT_COURSES_QUERY = gql `
  query DraftCoursesQuery {
    courses(
      sort: "id:desc"
      publicationState: PREVIEW
      filters: { publishedAt: { eq: null } }
      pagination: { pageSize: 1000 }
    ) {
      data {
        id
        attributes {
          publishedAt
        }
      }
    }
  }
`;
export const LATEST_COURSES_QUERY = gql `
  query LatestCoursesQuery($contentTypeId: ID!) {
    courses(sort: "publishedAt:desc", filters: { type: { id: { eq: $contentTypeId } }, draft: { eq: false } }, pagination: {pageSize: ${DEFAULT_WALL_LIMIT}}) {
      data {
        id
        attributes {
          ${COURSE_BASE_PROPS}
        }
      }
    }
  }
`;
