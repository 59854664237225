import { OramaClient, AnswerSession } from "@oramacloud/client";
import env from '../env.json'
import { useEffect, useState } from "react";
import { Message } from "src/types/orama";
import { InternalTypedDocument, Result } from "@orama/orama"
import { Profile } from "@learnn/sdk/src/api/profile";

export const oramaLessonsClient = new OramaClient({ 
    endpoint: env.ORAMA_LESSONS_INDEX_ENDPOINT, 
    api_key: env.ORAMA_LESSONS_INDEX_PUBLIC_KEY 
}) 

export const oramaCoursesClient = new OramaClient({ 
    endpoint: env.ORAMA_COURSES_INDEX_ENDPOINT, 
    api_key: env.ORAMA_COURSES_INDEX_PUBLIC_KEY 
})  

export type OramaAISearchOptions = {
    initialQuery?: string
    profile?: Profile
}
export const useOramaAISearch = <T>(oramaClient: OramaClient, options?: OramaAISearchOptions) => {
    const [messages, setMessages] = useState<Message[]>([])
    const [sources, setSources] = useState<Result<T>[]>([])
    const [loading, setLoading] = useState<boolean>(false)
    const [lastAssistantMessage, setLastAssistantMessage] = useState<string | null>('')
    const [answerSession, setAnswerSession] = useState<AnswerSession>()
    
    useEffect(() => {
        const answerSession = oramaClient.createAnswerSession({
            userContext: options?.profile ? buildUserContext(options.profile) : undefined,
            inferenceType: 'documentation',
            events: {
                onMessageChange: (messages) => {
                    if (messages.length === 0)
                        return;

                    setMessages(messages)
        
                    if (messages[messages.length - 1].role === 'assistant')
                        setLastAssistantMessage(messages[messages.length - 1].content)
                    else
                        setLastAssistantMessage(messages[messages.length - 2].role)
                },
                onMessageLoading: (loading) => {
                    setLoading(loading)
                },
                // @ts-ignore
                onSourceChange: (sources: Result<T>[]) => {
                    setSources(sources)
                }
            }
        })
        setAnswerSession(answerSession)

        if (options?.initialQuery) {
            answerSession.ask({
                term: options.initialQuery
            })
        }
    }, [])

    return {
        messages,
        lastAssistantMessage,
        sources,
        loading,
        sendMessage: (message: string) => {
            if (answerSession) {
                setLastAssistantMessage(null)
                answerSession.ask({
                    term: message
                })
            }
        },
    }

}

const buildUserContext = (profile: Profile) => {
    let userContext = '';

    if (profile.name)
        userContext = `Questo utente si chiama ${profile.name} ${profile.lastName ?? ''}.`

    if (profile.profession)
        userContext = `${userContext} La sua professione è ${profile.profession} ${profile.company ? `presso ${profile.company}` : ''}.`

    return userContext
}

export type LessonResult = {
    id: string
    title?: string
    description?: string
    courseId?: string
    courseName?: string
    courseDescription?: string
}
export type CourseResult = InternalTypedDocument<{
    id: string
    title: string
    description: string
}>