import { Observable, retry } from 'rxjs';
import * as O from 'fp-ts/Option';
import * as E from 'fp-ts/Either';
import { extractThumbUrlFromImage, getObjectDate, getTextDate, isVerifiedAvailable, normalizeGraphqlResponse, } from '../utils';
import { COMING_CONTENTS, COMING_JOBS, getQuizCourse, toProgress, toLastCourseprogress, filterCompletedLessons, } from './libs';
export const dynamicSections = (client, engagement, recommender, quiz, course, configuration, player, profile, path) => (results, userId, options) => {
    const handlerDynamicSearch = (section) => {
        return new Observable(subscriber => {
            subscriber.next({
                __typename: section.__typename,
                data: O.some(E.right(section)),
            });
        });
    };
    const handlerDynamicSearchBanner = (section) => {
        return new Observable(subscriber => {
            subscriber.next({
                __typename: section.__typename,
                data: O.some(E.right(section)),
            });
        });
    };
    const handlerDynamicLastProgresses = (section) => {
        return new Observable(subscriber => {
            subscriber.next({
                __typename: section.__typename,
                data: O.none,
            });
            new Promise(async () => {
                const { lastProgresses } = await engagement.getHome([]).catch(e => {
                    subscriber.error(e);
                    throw e;
                });
                subscriber.next({
                    __typename: section.__typename,
                    data: O.some(E.right({
                        ...section,
                        data: toProgress(lastProgresses),
                    })),
                });
            }).catch(e => {
                subscriber.error(e);
            });
        });
    };
    const handlerDynamicLastSeenCourse = (section) => {
        return new Observable(subscriber => {
            subscriber.next({
                __typename: section.__typename,
                data: O.none,
            });
            new Promise(async () => {
                const { lastProgresses } = await engagement.getHome([]).catch(e => {
                    subscriber.error(e);
                    throw e;
                });
                subscriber.next({
                    __typename: section.__typename,
                    data: O.some(E.right({
                        ...section,
                        data: toLastCourseprogress(lastProgresses),
                    })),
                });
            }).catch(e => {
                subscriber.error(e);
            });
        });
    };
    const handlerDynamicCompletedCourses = (section) => {
        return new Observable(subscriber => {
            subscriber.next({
                __typename: section.__typename,
                data: O.none,
            });
            new Promise(async () => {
                const courses = await engagement.getCompletedCourses().catch(e => {
                    subscriber.error(e);
                    throw e;
                });
                subscriber.next({
                    __typename: section.__typename,
                    data: O.some(E.right({
                        ...section,
                        data: courses,
                    })),
                });
            }).catch(e => {
                subscriber.error(e);
            });
        });
    };
    const handlerFeaturedContent = (section) => {
        return new Observable(subscriber => {
            subscriber.next({
                __typename: section.__typename,
                data: O.some(E.right({
                    ...section,
                    appMobile: section.appMobile.map((content) => ({
                        ...content,
                        coverImage: extractThumbUrlFromImage(content.image, 'large'),
                    })),
                    webApp: section.webApp.map((content) => ({
                        ...content,
                        coverImage: extractThumbUrlFromImage(content.image, 'large'),
                    })),
                })),
            });
        });
    };
    const handlerProfile = (section) => {
        return new Observable(subscriber => {
            subscriber.next({
                __typename: section.__typename,
                data: O.none,
            });
            new Promise(async () => {
                try {
                    const profileResult = await profile.get(userId);
                    subscriber.next({
                        __typename: section.__typename,
                        data: O.some(E.right({
                            ...section,
                            data: profileResult,
                        })),
                    });
                }
                catch (e) {
                    if (e instanceof Error && e.message === 'Not Found')
                        subscriber.next({
                            __typename: section.__typename,
                            data: O.some(E.right({
                                ...section,
                                data: {},
                            })),
                        });
                }
            }).catch(e => {
                subscriber.error(e);
            });
        });
    };
    const handlerStaticCourse = (section) => {
        const applyEngagement = (engagement) => (c) => {
            return {
                ...c,
                title: c.title ?? '',
                shortTitle: c.shortTitle ?? c.title ?? '',
                progress: engagement[c.id] ? engagement[c.id].progress : 0,
                myLearnnId: engagement[c.id] ? engagement[c.id].myLearnnId : undefined,
            };
        };
        return new Observable(subscriber => {
            subscriber.next({
                __typename: section.__typename,
                data: O.none,
            });
            new Promise(async () => {
                let coursesData = [];
                for (const { course: sectionCourse } of section.courses) {
                    try {
                        const courseData = await course.getCourseBaseInfo(sectionCourse.id);
                        coursesData.push({
                            ...courseData,
                        });
                    }
                    catch (e) {
                        console.warn(`Corso ${sectionCourse.id} non trovato. Ignorato`);
                    }
                }
                subscriber.next({
                    __typename: section.__typename,
                    data: O.some(E.right({
                        ...section,
                        courses: coursesData,
                    })),
                });
                const coursesId = section.courses.map((p) => p.course.id).flat();
                const { coursesEngagement } = await engagement.getHome(coursesId);
                subscriber.next({
                    __typename: section.__typename,
                    data: O.some(E.right({
                        ...section,
                        courses: coursesData.map(applyEngagement(coursesEngagement)),
                    })),
                });
            }).catch(e => {
                console.error(e);
            });
        });
    };
    const handlerStaticLessons = (section) => {
        const applyEngagement = (engagement) => (l) => {
            return {
                ...l,
                progress: engagement[l.id] ? engagement[l.id].progress : 0,
                myLearnnId: engagement[l.id] ? engagement[l.id].myLearnnId : undefined,
            };
        };
        return new Observable(subscriber => {
            subscriber.next({
                __typename: section.__typename,
                data: O.none,
            });
            new Promise(async () => {
                let lessonsData = [];
                await Promise.all(section.lessons.map(async (lesson, index) => {
                    try {
                        const lessonData = await player.getLessonBase(lesson.lesson.id);
                        lessonsData[index] = lessonData;
                    }
                    catch (e) {
                        console.log(`Lezione ${lesson.lesson.id} non trovata. Ignorato`);
                    }
                }));
                lessonsData = lessonsData.filter(item => item !== undefined);
                subscriber.next({
                    __typename: section.__typename,
                    data: O.some(E.right({
                        ...section,
                        lessons: lessonsData,
                    })),
                });
                const lessonsId = section.lessons.map((p) => p.lesson.id).flat();
                const { lessonsEngagement } = await engagement.getLessons(lessonsId);
                subscriber.next({
                    __typename: section.__typename,
                    data: O.some(E.right({
                        ...section,
                        lessons: lessonsData
                            .map(applyEngagement(lessonsEngagement))
                            .filter(filterCompletedLessons),
                    })),
                });
            }).catch(e => {
                console.error(e);
            });
        });
    };
    const handlerStaticPath = (section) => {
        return new Observable(subscriber => {
            subscriber.next({
                __typename: section.__typename,
                data: O.none,
            });
            new Promise(async () => {
                let pathsData = [];
                for (const { path: sectionPath } of section.paths) {
                    try {
                        const pathData = await path.getPathById(sectionPath.id);
                        pathsData.push({
                            ...pathData,
                        });
                    }
                    catch (e) {
                        console.warn(`Corso ${sectionPath.id} non trovato. Ignorato`);
                    }
                }
                subscriber.next({
                    __typename: section.__typename,
                    data: O.some(E.right({
                        ...section,
                        paths: pathsData,
                    })),
                });
            }).catch(e => {
                console.error(e);
            });
        });
    };
    const handlerComingContent = (section) => {
        return new Observable(subscriber => {
            subscriber.next({
                __typename: section.__typename,
                data: O.none,
            });
            new Promise(async () => {
                const result = await client
                    .query({
                    query: section.type === 'jobs' ? COMING_JOBS : COMING_CONTENTS,
                })
                    .then(normalizeGraphqlResponse)
                    .catch(e => {
                    subscriber.error(e);
                    throw e;
                });
                const comingJobs = result.data?.comingContents.map((f) => {
                    return {
                        ...f,
                        releaseDateString: f.releaseDate,
                        releaseDate: getTextDate(f.releaseDate),
                        releaseDateObject: getObjectDate(f.releaseDate, 'short'),
                        ...{ coverImageUrl: extractThumbUrlFromImage(f.coverImage, 'large') },
                    };
                });
                subscriber.next({
                    __typename: section.__typename,
                    data: O.some(E.right({
                        ...section,
                        data: comingJobs,
                    })),
                });
            }).catch(e => {
                subscriber.next({
                    __typename: section.__typename,
                    data: O.some(E.left(e.toString())),
                });
            });
        });
    };
    const handlerMostViewed = (section) => {
        return new Observable(subscriber => {
            subscriber.next({
                __typename: section.__typename,
                data: O.none,
            });
            new Promise(async () => {
                let courses = null;
                switch (section.mostViewedEntity) {
                    case 'courses': {
                        courses = await engagement
                            .getCourseCharts(section.mostViewedInterval === 'month'
                            ? 'most-viewed-month'
                            : section.mostViewedInterval === 'overtime'
                                ? 'most-viewed-overtime'
                                : 'most-viewed-overtime', section.limit)
                            .catch(e => {
                            subscriber.error(e);
                            throw e;
                        });
                        break;
                    }
                    default: {
                        courses = await engagement
                            .getCourseCharts(section.mostViewedInterval === 'month'
                            ? 'most-viewed-month'
                            : section.mostViewedInterval === 'overtime'
                                ? 'most-viewed-overtime'
                                : 'most-viewed-overtime', section.limit)
                            .catch(e => {
                            subscriber.error(e);
                            throw e;
                        });
                    }
                }
                if (courses !== null) {
                    let coursesData = [];
                    await Promise.all(courses.map(async (courseId) => {
                        try {
                            const courseData = await course.getCourseBaseInfo(courseId['id'] ?? courseId);
                            coursesData.push({
                                ...courseData,
                                score: 0,
                            });
                        }
                        catch (e) {
                            console.warn(`Corso ${courseId} non trovato. Ignorato`);
                        }
                    }));
                    subscriber.next({
                        __typename: section.__typename,
                        data: O.some(E.right({
                            ...section,
                            entity: section.mostViewedEntity,
                            interval: section.mostViewedInterval,
                            data: coursesData,
                        })),
                    });
                    const coursesEngagement = await engagement.getCourses(courses);
                    const { coursesEngagement: progresses } = coursesEngagement;
                    (coursesData = coursesData.map(courseData => ({
                        ...courseData,
                        score: 0,
                        progress: progresses[courseData.id] ? progresses[courseData.id].progress : 0,
                        myLearnnId: progresses[courseData.id]
                            ? progresses[courseData.id].myLearnnId
                            : undefined,
                    }))),
                        subscriber.next({
                            __typename: section.__typename,
                            data: O.some(E.right({
                                ...section,
                                entity: section.mostViewedEntity,
                                interval: section.mostViewedInterval,
                                data: coursesData,
                            })),
                        });
                }
                else {
                    subscriber.error('Corsi non trovati');
                }
            }).catch(e => {
                console.error(e);
            });
        });
    };
    const handlerMostUseful = (section) => {
        return new Observable(subscriber => {
            subscriber.next({
                __typename: section.__typename,
                data: O.none,
            });
            new Promise(async () => {
                let courses = null;
                switch (section.mostUsefulEntity) {
                    case 'courses': {
                        courses = await engagement
                            .getCourseCharts(section.mostUsefulInterval === 'month'
                            ? 'most-useful-month'
                            : section.mostUsefulInterval === 'overtime'
                                ? 'most-useful-overtime'
                                : 'most-useful-overtime', section.limit)
                            .catch(e => {
                            subscriber.error(e);
                            throw e;
                        });
                        break;
                    }
                    default: {
                        courses = await engagement
                            .getCourseCharts(section.mostUsefulInterval === 'month'
                            ? 'most-useful-month'
                            : section.mostUsefulInterval === 'overtime'
                                ? 'most-useful-overtime'
                                : 'most-useful-overtime', section.limit)
                            .catch(e => {
                            subscriber.error(e);
                            throw e;
                        });
                    }
                }
                if (courses !== null) {
                    let coursesData = [];
                    await Promise.all(courses.map(async (courseId) => {
                        try {
                            const courseData = await course.getCourseBaseInfo(courseId['id'] ?? courseId);
                            coursesData.push({
                                ...courseData,
                                score: 0,
                            });
                        }
                        catch (e) {
                            console.warn(`Corso ${courseId} non trovato. Ignorato`);
                        }
                    }));
                    subscriber.next({
                        __typename: section.__typename,
                        data: O.some(E.right({
                            ...section,
                            entity: section.mostUsefulEntity,
                            interval: section.mostUsefulInterval,
                            data: coursesData,
                        })),
                    });
                    const coursesEngagement = await engagement.getCourses(courses);
                    const { coursesEngagement: progresses } = coursesEngagement;
                    (coursesData = coursesData.map(courseData => ({
                        ...courseData,
                        score: 0,
                        progress: progresses[courseData.id] ? progresses[courseData.id].progress : 0,
                        myLearnnId: progresses[courseData.id]
                            ? progresses[courseData.id].myLearnnId
                            : undefined,
                    }))),
                        subscriber.next({
                            __typename: section.__typename,
                            data: O.some(E.right({
                                ...section,
                                entity: section.mostUsefulEntity,
                                interval: section.mostUsefulInterval,
                                data: coursesData,
                            })),
                        });
                }
                else {
                    subscriber.error('Corsi non trovati');
                }
            }).catch(e => {
                console.error(e);
            });
        });
    };
    const handlerTrendingNow = (section) => {
        return new Observable(subscriber => {
            subscriber.next({
                __typename: section.__typename,
                data: O.none,
            });
            new Promise(async () => {
                let courses = await engagement.getCourseCharts('trending-now', section.limit).catch(e => {
                    subscriber.error(e);
                    throw e;
                });
                if (courses !== null) {
                    let coursesData = [];
                    await Promise.all(courses.map(async (courseId) => {
                        try {
                            const courseData = await course.getCourseBaseInfo(courseId['id'] ?? courseId);
                            coursesData.push({
                                ...courseData,
                                score: 0,
                            });
                        }
                        catch (e) {
                            console.warn(`Corso ${courseId} non trovato. Ignorato`);
                        }
                    }));
                    subscriber.next({
                        __typename: section.__typename,
                        data: O.some(E.right({
                            ...section,
                            data: coursesData,
                        })),
                    });
                    const coursesEngagement = await engagement.getCourses(courses);
                    const { coursesEngagement: progresses } = coursesEngagement;
                    (coursesData = coursesData.map(courseData => ({
                        ...courseData,
                        score: 0,
                        progress: progresses[courseData.id] ? progresses[courseData.id].progress : 0,
                        myLearnnId: progresses[courseData.id]
                            ? progresses[courseData.id].myLearnnId
                            : undefined,
                    }))),
                        subscriber.next({
                            __typename: section.__typename,
                            data: O.some(E.right({
                                ...section,
                                data: coursesData,
                            })),
                        });
                }
                else {
                    subscriber.error('Corsi non trovati');
                }
            }).catch(e => {
                console.error(e);
            });
        });
    };
    const handlerTopPicksForYou = (section, userId) => {
        return new Observable(subscriber => {
            subscriber.next({
                __typename: section.__typename,
                data: O.none,
            });
            new Promise(async () => {
                let courses = await recommender.getTopPicks(userId, section.limit).catch(e => {
                    subscriber.error(e);
                    throw e;
                });
                if (courses !== null &&
                    courses.recommendations !== null &&
                    courses.recommendations.length > 0) {
                    const coursesEngagement = await engagement.getCourses(courses.recommendations.map(i => i.itemId));
                    const { coursesEngagement: progresses } = coursesEngagement;
                    const coursesData = [];
                    await Promise.all(courses.recommendations.map(async (recommendedCourse) => {
                        try {
                            const courseData = await course.getCourseBaseInfo(recommendedCourse.itemId);
                            if (progresses[recommendedCourse.itemId].progress <
                                options.recommendedCourseHideThreshold)
                                coursesData.push({
                                    ...courseData,
                                    score: Number(recommendedCourse.score),
                                    progress: progresses[recommendedCourse.itemId]
                                        ? progresses[recommendedCourse.itemId].progress
                                        : 0,
                                    myLearnnId: progresses[recommendedCourse.itemId]
                                        ? progresses[recommendedCourse.itemId].myLearnnId
                                        : undefined,
                                });
                        }
                        catch (e) {
                            console.warn(`Corso ${recommendedCourse.itemId} non trovato. Ignorato`);
                        }
                    }));
                    subscriber.next({
                        __typename: section.__typename,
                        data: O.some(E.right({
                            ...section,
                            data: coursesData,
                        })),
                    });
                }
                else {
                    subscriber.error('Corsi non trovati');
                }
            }).catch(e => {
                subscriber.error(e);
            });
        });
    };
    const handlerDynamicQuiz = (section, userId) => {
        return new Observable(subscriber => {
            subscriber.next({
                __typename: section.__typename,
                data: O.none,
            });
            new Promise(async () => {
                switch (section.quizEntity) {
                    case 'dailyQuiz': {
                        let dailyQuiz = await quiz
                            .getDailyList(userId, { maxReleaseDate: new Date() })
                            .catch(e => {
                            subscriber.error(e);
                            throw e;
                        });
                        if (dailyQuiz.filter(q => !q.attempt).length > 0) {
                            subscriber.next({
                                __typename: section.__typename,
                                data: O.some(E.right({
                                    ...section,
                                    data: dailyQuiz.filter(q => !q.attempt),
                                })),
                            });
                        }
                        else {
                            subscriber.error('Nessun quiz disponibile');
                        }
                        break;
                    }
                    case 'verified': {
                        let coursesId = await configuration.quizAvailabilityList().catch(e => {
                            subscriber.error(e);
                            throw e;
                        });
                        let coursesProgressHash = {};
                        if (coursesId.length > 0) {
                            const coursesData = [];
                            try {
                                const coursesEngagement = await engagement.getCourses(coursesId);
                                const { coursesEngagement: progresses } = coursesEngagement;
                                coursesProgressHash = progresses;
                            }
                            catch (error) {
                                console.log(error);
                            }
                            for (const id of coursesId) {
                                try {
                                    if (isVerifiedAvailable(coursesProgressHash[id].progress ?? 0)) {
                                        const quizStatus = await quiz.getAttemptsByCourse(id, userId);
                                        if (quizStatus.passed === false && quizStatus.retryDate <= new Date()) {
                                            coursesData.push(await getQuizCourse(client)(id));
                                        }
                                    }
                                }
                                catch (e) {
                                    console.warn(`Corso ${id} non inserito`);
                                }
                            }
                            if (coursesData.length) {
                                subscriber.next({
                                    __typename: section.__typename,
                                    data: O.some(E.right({
                                        ...section,
                                        data: coursesData,
                                    })),
                                });
                            }
                            else {
                                subscriber.error('Nessun quiz disponibile');
                            }
                        }
                        else {
                            subscriber.error('Nessun quiz disponibile');
                        }
                    }
                }
            }).catch(e => {
                subscriber.error(e);
            });
        });
    };
    const handlerSavedContents = (section) => {
        return new Observable(subscriber => {
            subscriber.next({
                __typename: section.__typename,
                data: O.none,
            });
            const applyEngagement = (c, engagement) => ({
                ...c,
                progress: engagement && engagement[c.id] ? engagement[c.id].progress : 0,
                myLearnnId: engagement && engagement[c.id] ? engagement[c.id].myLearnnId : undefined,
            });
            new Promise(async () => {
                try {
                    const data = await engagement.getSavedCourses().catch(e => {
                        subscriber.error(e);
                        throw e;
                    });
                    let courseIds = data.map(c => c.courseId);
                    if (section.limit)
                        courseIds = courseIds.splice(0, section.limit);
                    let response = [];
                    for (const courseId of courseIds) {
                        response.push(await course.getCourseBaseInfo(courseId));
                    }
                    if (section.contentType)
                        response = response.filter(x => x.type.id === section.contentType?.id);
                    subscriber.next({
                        __typename: section.__typename,
                        data: O.some(E.right({
                            ...section,
                            data: response,
                        })),
                    });
                    const coursesEngagement = await engagement.getCourses(courseIds);
                    const { coursesEngagement: coursesHash } = coursesEngagement;
                    response = response.map(c => applyEngagement(c, coursesHash));
                    subscriber.next({
                        __typename: section.__typename,
                        data: O.some(E.right({
                            ...section,
                            data: response,
                        })),
                    });
                }
                catch (e) {
                    console.error(e);
                }
            }).catch(e => {
                subscriber.error(e);
            });
        });
    };
    const handlerDynamicLatestContent = (section) => {
        const applyEngagement = (engagement) => (p) => {
            return p.map((c) => ({
                ...c,
                progress: engagement[c.id] ? engagement[c.id].progress : 0,
                myLearnnId: engagement[c.id] ? engagement[c.id].myLearnnId : undefined,
            }));
        };
        return new Observable(subscriber => {
            subscriber.next({
                __typename: section.__typename,
                data: O.none,
            });
            new Promise(async () => {
                let courses = await course.getLatest(section.contentType.id).catch(e => {
                    subscriber.error(e);
                    throw e;
                });
                courses = courses.slice(0, section.limit ?? 10);
                subscriber.next({
                    __typename: section.__typename,
                    data: O.some(E.right({
                        ...section,
                        courses: courses,
                    })),
                });
                const coursesId = courses.map(p => p.id).flat();
                const { coursesEngagement } = await engagement.getHome(coursesId);
                subscriber.next({
                    __typename: section.__typename,
                    data: O.some(E.right({
                        ...section,
                        courses: applyEngagement(coursesEngagement)(courses),
                    })),
                });
            }).catch(e => {
                console.error(e);
            });
        });
    };
    const handlerDynamicDynamicLessons = (section) => {
        const applyEngagement = (engagement) => (l) => {
            return {
                ...l,
                progress: engagement[l.id] ? engagement[l.id].progress : 0,
                myLearnnId: engagement[l.id] ? engagement[l.id].myLearnnId : undefined,
            };
        };
        return new Observable(subscriber => {
            subscriber.next({
                __typename: section.__typename,
                data: O.none,
            });
            new Promise(async () => {
                const lessonsData = await player.getLessonsBase({
                    limit: section.limit ?? 10,
                    tagsId: section.tags.map(x => x.id),
                });
                subscriber.next({
                    __typename: section.__typename,
                    data: O.some(E.right({
                        ...section,
                        lessons: lessonsData,
                    })),
                });
                const lessonsId = lessonsData.map((p) => p.id).flat();
                const { lessonsEngagement } = await engagement.getLessons(lessonsId);
                subscriber.next({
                    __typename: section.__typename,
                    data: O.some(E.right({
                        ...section,
                        lessons: lessonsData
                            .map(applyEngagement(lessonsEngagement))
                            .filter(filterCompletedLessons),
                    })),
                });
            }).catch(e => {
                console.error(e);
            });
        });
    };
    const handlerStaticMenuSection = (section) => {
        return new Observable(subscriber => {
            subscriber.next({
                __typename: section.__typename,
                data: O.some(E.right(section)),
            });
        });
    };
    const handlerMostViewedBySegment = (section) => {
        return new Observable(subscriber => {
            subscriber.next({
                __typename: section.__typename,
                data: O.none,
            });
            new Promise(async () => {
                let courses = [];
                let criterionValue = '';
                const userProfile = await profile.get(userId);
                const coursesBySegments = await engagement
                    .getCourseBySegment(`most-viewed-${section.interval}-per-${section.criterion}`, section.limit)
                    .catch(e => {
                    subscriber.error(e);
                    throw e;
                });
                switch (section.criterion) {
                    case 'companyrole': {
                        const coursesBySegment = coursesBySegments.filter(x => x.criterion === userProfile.companyRole).length > 0
                            ? coursesBySegments.filter(x => x.criterion === userProfile.companyRole)[0]
                            : null;
                        if (userProfile.companyRole && coursesBySegment) {
                            criterionValue = userProfile.companyRole;
                            courses = coursesBySegment.courseIds;
                        }
                        break;
                    }
                    case 'goal': {
                        const coursesBySegment = coursesBySegments.filter(x => x.criterion === userProfile.goal).length > 0
                            ? coursesBySegments.filter(x => x.criterion === userProfile.goal)[0]
                            : null;
                        if (userProfile.goal && coursesBySegment) {
                            criterionValue = userProfile.goal;
                            courses = coursesBySegment.courseIds;
                        }
                        break;
                    }
                    default: {
                        subscriber.error('Criterion not handled');
                    }
                }
                if (courses !== null) {
                    let coursesData = [];
                    for (const courseId of courses) {
                        try {
                            const courseData = await course.getCourseBaseInfo(courseId['id'] ?? courseId);
                            coursesData.push(courseData);
                        }
                        catch (e) {
                            console.warn(`Corso ${courseId} non trovato. Ignorato`);
                        }
                    }
                    subscriber.next({
                        __typename: section.__typename,
                        data: O.some(E.right({
                            ...section,
                            title: `${section.title} ${criterionValue}`,
                            courses: coursesData,
                        })),
                    });
                    const coursesEngagement = await engagement.getCourses(courses);
                    const { coursesEngagement: progresses } = coursesEngagement;
                    (coursesData = coursesData.map(courseData => ({
                        ...courseData,
                        score: 0,
                        progress: progresses[courseData.id] ? progresses[courseData.id].progress : 0,
                        myLearnnId: progresses[courseData.id]
                            ? progresses[courseData.id].myLearnnId
                            : undefined,
                    }))),
                        subscriber.next({
                            __typename: section.__typename,
                            data: O.some(E.right({
                                ...section,
                                title: `${section.title} ${criterionValue}`,
                                courses: coursesData,
                            })),
                        });
                }
                else {
                    subscriber.error('Corsi non trovati');
                }
            }).catch(e => {
                console.error(e);
            });
        });
    };
    return new Observable(subscriber => {
        let customHomeState;
        for (const [key, section] of results.entries()) {
            if (section.hide && section.hide.includes(options.client)) {
                continue;
            }
            switch (section.__typename) {
                case 'ComponentSectionDynamicSearch': {
                    handlerDynamicSearch(section)
                        .pipe(retry(3))
                        .subscribe({
                        next: section => {
                            customHomeState = {
                                ...customHomeState,
                                [key]: section,
                            };
                            subscriber.next(customHomeState);
                        },
                        error: e => {
                            customHomeState = {
                                ...customHomeState,
                                [key]: {
                                    __typename: section.__typename,
                                    data: O.some(E.left(e)),
                                },
                            };
                            subscriber.next(customHomeState);
                        },
                    });
                    break;
                }
                case 'ComponentSectionDynamicLastProgresses': {
                    handlerDynamicLastProgresses(section)
                        .pipe(retry(3))
                        .subscribe({
                        next: section => {
                            customHomeState = {
                                ...customHomeState,
                                [key]: section,
                            };
                            subscriber.next(customHomeState);
                        },
                        error: e => {
                            customHomeState = {
                                ...customHomeState,
                                [key]: {
                                    __typename: section.__typename,
                                    data: O.some(E.left(e)),
                                },
                            };
                            subscriber.next(customHomeState);
                        },
                    });
                    break;
                }
                case 'ComponentSectionStaticFeatureContent': {
                    handlerFeaturedContent(section)
                        .pipe(retry(3))
                        .subscribe({
                        next: section => {
                            customHomeState = {
                                ...customHomeState,
                                [key]: section,
                            };
                            subscriber.next(customHomeState);
                        },
                        error: e => {
                            customHomeState = {
                                ...customHomeState,
                                [key]: {
                                    __typename: section.__typename,
                                    data: O.some(E.left(e)),
                                },
                            };
                            subscriber.next(customHomeState);
                        },
                    });
                    break;
                }
                case 'ComponentSectionStaticCourses': {
                    handlerStaticCourse(section)
                        .pipe(retry(3))
                        .subscribe({
                        next: section => {
                            customHomeState = {
                                ...customHomeState,
                                [key]: section,
                            };
                            subscriber.next(customHomeState);
                        },
                        error: e => {
                            customHomeState = {
                                ...customHomeState,
                                [key]: {
                                    __typename: section.__typename,
                                    data: O.some(E.left(e)),
                                },
                            };
                            subscriber.next(customHomeState);
                        },
                    });
                    break;
                }
                case 'ComponentSectionStaticLessons': {
                    handlerStaticLessons(section)
                        .pipe(retry(3))
                        .subscribe({
                        next: section => {
                            customHomeState = {
                                ...customHomeState,
                                [key]: section,
                            };
                            subscriber.next(customHomeState);
                        },
                        error: e => {
                            customHomeState = {
                                ...customHomeState,
                                [key]: {
                                    __typename: section.__typename,
                                    data: O.some(E.left(e)),
                                },
                            };
                            subscriber.next(customHomeState);
                        },
                    });
                    break;
                }
                case 'ComponentSectionDynamicComingContent': {
                    handlerComingContent(section)
                        .pipe(retry(3))
                        .subscribe({
                        next: section => {
                            customHomeState = {
                                ...customHomeState,
                                [key]: section,
                            };
                            subscriber.next(customHomeState);
                        },
                        error: e => {
                            customHomeState = {
                                ...customHomeState,
                                [key]: {
                                    __typename: section.__typename,
                                    data: O.some(E.left(e)),
                                },
                            };
                            subscriber.next(customHomeState);
                        },
                    });
                    break;
                }
                case 'ComponentSectionDynamicMostViewed': {
                    handlerMostViewed(section)
                        .pipe(retry(3))
                        .subscribe({
                        next: section => {
                            customHomeState = {
                                ...customHomeState,
                                [key]: section,
                            };
                            subscriber.next(customHomeState);
                        },
                        error: e => {
                            customHomeState = {
                                ...customHomeState,
                                [key]: {
                                    __typename: section.__typename,
                                    data: O.some(E.left(e)),
                                },
                            };
                            subscriber.next(customHomeState);
                        },
                    });
                    break;
                }
                case 'ComponentSectionDynamicMostUseful': {
                    handlerMostUseful(section)
                        .pipe(retry(3))
                        .subscribe({
                        next: section => {
                            customHomeState = {
                                ...customHomeState,
                                [key]: section,
                            };
                            subscriber.next(customHomeState);
                        },
                        error: e => {
                            customHomeState = {
                                ...customHomeState,
                                [key]: {
                                    __typename: section.__typename,
                                    data: O.some(E.left(e)),
                                },
                            };
                            subscriber.next(customHomeState);
                        },
                    });
                    break;
                }
                case 'ComponentSectionDynamicTrendingNow': {
                    handlerTrendingNow(section)
                        .pipe(retry(3))
                        .subscribe({
                        next: section => {
                            customHomeState = {
                                ...customHomeState,
                                [key]: section,
                            };
                            subscriber.next(customHomeState);
                        },
                        error: e => {
                            customHomeState = {
                                ...customHomeState,
                                [key]: {
                                    __typename: section.__typename,
                                    data: O.some(E.left(e)),
                                },
                            };
                            subscriber.next(customHomeState);
                        },
                    });
                    break;
                }
                case 'ComponentSectionDynamicTopPicksForYou': {
                    handlerTopPicksForYou(section, userId)
                        .pipe(retry(3))
                        .subscribe({
                        next: section => {
                            customHomeState = {
                                ...customHomeState,
                                [key]: section,
                            };
                            subscriber.next(customHomeState);
                        },
                        error: e => {
                            customHomeState = {
                                ...customHomeState,
                                [key]: {
                                    __typename: section.__typename,
                                    data: O.some(E.left(e)),
                                },
                            };
                            subscriber.next(customHomeState);
                        },
                    });
                    break;
                }
                case 'ComponentSectionDynamicQuiz': {
                    handlerDynamicQuiz(section, userId)
                        .pipe(retry(3))
                        .subscribe({
                        next: section => {
                            customHomeState = {
                                ...customHomeState,
                                [key]: section,
                            };
                            subscriber.next(customHomeState);
                        },
                        error: e => {
                            customHomeState = {
                                ...customHomeState,
                                [key]: {
                                    __typename: section.__typename,
                                    data: O.some(E.left(e)),
                                },
                            };
                            subscriber.next(customHomeState);
                        },
                    });
                    break;
                }
                case 'ComponentSectionDynamicSavedContents': {
                    handlerSavedContents(section)
                        .pipe(retry(3))
                        .subscribe({
                        next: section => {
                            customHomeState = {
                                ...customHomeState,
                                [key]: section,
                            };
                            subscriber.next(customHomeState);
                        },
                        error: e => {
                            customHomeState = {
                                ...customHomeState,
                                [key]: {
                                    __typename: section.__typename,
                                    data: O.some(E.left(e)),
                                },
                            };
                            subscriber.next(customHomeState);
                        },
                    });
                    break;
                }
                case 'ComponentSectionDynamicLastSeenCourse': {
                    handlerDynamicLastSeenCourse(section)
                        .pipe(retry(3))
                        .subscribe({
                        next: section => {
                            customHomeState = {
                                ...customHomeState,
                                [key]: section,
                            };
                            subscriber.next(customHomeState);
                        },
                        error: e => {
                            customHomeState = {
                                ...customHomeState,
                                [key]: {
                                    __typename: section.__typename,
                                    data: O.some(E.left(e)),
                                },
                            };
                            subscriber.next(customHomeState);
                        },
                    });
                    break;
                }
                case 'ComponentSectionDynamicCompletedCourses': {
                    handlerDynamicCompletedCourses(section)
                        .pipe(retry(3))
                        .subscribe({
                        next: section => {
                            customHomeState = {
                                ...customHomeState,
                                [key]: section,
                            };
                            subscriber.next(customHomeState);
                        },
                        error: e => {
                            customHomeState = {
                                ...customHomeState,
                                [key]: {
                                    __typename: section.__typename,
                                    data: O.some(E.left(e)),
                                },
                            };
                            subscriber.next(customHomeState);
                        },
                    });
                    break;
                }
                case 'ComponentSectionDynamicSearchBanner': {
                    handlerDynamicSearchBanner(section)
                        .pipe(retry(3))
                        .subscribe({
                        next: section => {
                            customHomeState = {
                                ...customHomeState,
                                [key]: section,
                            };
                            subscriber.next(customHomeState);
                        },
                        error: e => {
                            customHomeState = {
                                ...customHomeState,
                                [key]: {
                                    __typename: section.__typename,
                                    data: O.some(E.left(e)),
                                },
                            };
                            subscriber.next(customHomeState);
                        },
                    });
                    break;
                }
                case 'ComponentSectionStaticPaths': {
                    handlerStaticPath(section)
                        .pipe(retry(3))
                        .subscribe({
                        next: section => {
                            customHomeState = {
                                ...customHomeState,
                                [key]: section,
                            };
                            subscriber.next(customHomeState);
                        },
                        error: e => {
                            customHomeState = {
                                ...customHomeState,
                                [key]: {
                                    __typename: section.__typename,
                                    data: O.some(E.left(e)),
                                },
                            };
                            subscriber.next(customHomeState);
                        },
                    });
                    break;
                }
                case 'ComponentSectionDynamicLatestContent': {
                    handlerDynamicLatestContent(section)
                        .pipe(retry(3))
                        .subscribe({
                        next: section => {
                            customHomeState = {
                                ...customHomeState,
                                [key]: section,
                            };
                            subscriber.next(customHomeState);
                        },
                        error: e => {
                            customHomeState = {
                                ...customHomeState,
                                [key]: {
                                    __typename: section.__typename,
                                    data: O.some(E.left(e)),
                                },
                            };
                            subscriber.next(customHomeState);
                        },
                    });
                    break;
                }
                case 'ComponentSectionDynamicDynamicLessons': {
                    handlerDynamicDynamicLessons(section)
                        .pipe(retry(3))
                        .subscribe({
                        next: section => {
                            customHomeState = {
                                ...customHomeState,
                                [key]: section,
                            };
                            subscriber.next(customHomeState);
                        },
                        error: e => {
                            customHomeState = {
                                ...customHomeState,
                                [key]: {
                                    __typename: section.__typename,
                                    data: O.some(E.left(e)),
                                },
                            };
                            subscriber.next(customHomeState);
                        },
                    });
                    break;
                }
                case 'ComponentSectionStaticMenuSection': {
                    handlerStaticMenuSection(section)
                        .pipe(retry(3))
                        .subscribe({
                        next: section => {
                            customHomeState = {
                                ...customHomeState,
                                [key]: section,
                            };
                            subscriber.next(customHomeState);
                        },
                        error: e => {
                            customHomeState = {
                                ...customHomeState,
                                [key]: {
                                    __typename: section.__typename,
                                    data: O.some(E.left(e)),
                                },
                            };
                            subscriber.next(customHomeState);
                        },
                    });
                    break;
                }
                case 'ComponentSectionDynamicProfile': {
                    handlerProfile(section)
                        .pipe(retry(3))
                        .subscribe({
                        next: section => {
                            customHomeState = {
                                ...customHomeState,
                                [key]: section,
                            };
                            subscriber.next(customHomeState);
                        },
                        error: e => {
                            customHomeState = {
                                ...customHomeState,
                                [key]: {
                                    __typename: section.__typename,
                                    data: O.some(E.left(e)),
                                },
                            };
                            subscriber.next(customHomeState);
                        },
                    });
                    break;
                }
                case 'ComponentSectionDynamicMostViewedBySegment': {
                    handlerMostViewedBySegment(section)
                        .pipe(retry(3))
                        .subscribe({
                        next: section => {
                            customHomeState = {
                                ...customHomeState,
                                [key]: section,
                            };
                            subscriber.next(customHomeState);
                        },
                        error: e => {
                            customHomeState = {
                                ...customHomeState,
                                [key]: {
                                    __typename: section.__typename,
                                    data: O.some(E.left(e)),
                                },
                            };
                            subscriber.next(customHomeState);
                        },
                    });
                    break;
                }
            }
        }
    });
};
export const QUERY_SECTIONS = `
      container {
        __typename
        ... on ComponentSectionStaticCourses {
          __typename
          id
          title
          subtitle
          classNames
          courses {
            course {
              data {
                id
              }
            }
          }
          hide
        }
        ... on ComponentSectionStaticLessons {
          __typename
          id
          title
          subtitle
          classNames
          lessons {
            lesson {
              data {
                id
              }
            }
          }
          hide
        }
        ... on ComponentSectionStaticPaths {
          __typename
          id
          title
          classNames
          paths {
            path {
              data {
                id
              }
            }
          }
          hide
        }
        ... on ComponentSectionStaticFeatureContent {
          __typename
          id
          title
          appMobile {
            id
            title
            subtitle
            image {
              data {
               id
                attributes {
                  formats
                }
              }
            }
            url
          }
          webApp {
            id
            title
            subtitle
            image {
              data {
               id
                attributes {
                  formats
                }
              }
            }
            url
          }
          classNames
          hide
        }
        ... on ComponentSectionDynamicLastProgresses {
          __typename
          id
          title
          subtitle
          dynamicEntity: entity
          classNames
          hide
        }
        ... on ComponentSectionDynamicMostUseful {
          __typename
          id
          title
          subtitle
          mostUsefulEntity: entity
          mostUsefulInterval: interval
          limit
          chart
          classNames
          hide
        }
        ... on ComponentSectionDynamicMostViewed {
          __typename
          id
          title
          subtitle
          mostViewedEntity: entity
          mostViewedInterval: interval
          limit
          chart
          classNames
          hide
        }
        ... on ComponentSectionDynamicSearch {
          __typename
          id
          title
          subtitle
          classNames
          hide
        }
        ... on ComponentSectionDynamicComingContent {
          __typename
          id
          title
          showAllText
          type
          subtitle
          classNames
          hide
        }
        ... on ComponentSectionDynamicTopPicksForYou {
          __typename
          id
          title
          subtitle
          limit
          classNames
          hide
        }
        ... on ComponentSectionDynamicTrendingNow {
          __typename
          id
          title
          subtitle
          limit
          chart
          classNames
          hide
        }
        ... on ComponentSectionDynamicQuiz {
          __typename
          id
          title
          subtitle
          quizEntity: entity
          classNames
          hide
        }
        ... on ComponentSectionDynamicSavedContents {
          __typename
          id
          title
          subtitle
          showAllText
          limit
          classNames
          contentType {
            data {
              id
              attributes {
                title
                slug
              }
            }
          }
          hide
        }
        ... on ComponentSectionDynamicSearchBanner {
          __typename
          id
          title
          subtitle
          placeholder
          buttonText
          showCategories
          classNames
          hide
        }
        ... on ComponentSectionDynamicLastSeenCourse {
          __typename
          id
          title
          subtitle
          classNames
          hide
        }
        ... on ComponentSectionDynamicCompletedCourses {
          __typename
          id
          title
          subtitle
          classNames
          hide
        }
        ... on ComponentSectionDynamicLatestContent {
          __typename
          id
          title
          contentType {
            data {
              id
              attributes {
                title
                slug
              }
            }
          }
          limit
          subtitle
          classNames
          hide
        }
        ... on ComponentSectionDynamicDynamicLessons {
          __typename
          id
          title
          tags {
            data {
              id
              attributes {
                name
              }
            }
          }
          limit
          subtitle
          classNames
          hide
        }
        ... on ComponentSectionStaticMenuSection {
          __typename
          id
          title
          items {
              label
              url
          }
          hide
        }
        ... on ComponentSectionDynamicProfile {
          __typename
          id
          title
          classNames
          hide
        }
        ... on ComponentSectionDynamicMostViewedBySegment {
          __typename
          id
          title
          subtitle
          criterion
          interval
          limit
          classNames
          hide
        }
      }
`;
