import { Observable } from 'rxjs';
export const progressiveSearch = (searchByKeyword, engagement) => keyword => {
    return new Observable(subscriber => {
        new Promise(async () => {
            const searchResult = await searchByKeyword(keyword);
            let result = {
                courses: searchResult.courses.map(c => applyCourseEngagement(c, null)),
                lessons: searchResult.lessons.map(l => applyLessonEngagement(l, null)),
                paths: searchResult.paths,
            };
            subscriber.next(result);
            await Promise.all([
                new Promise(async () => {
                    const courseIds = searchResult.courses.map((c) => c.id);
                    const lessonIds = searchResult.lessons.map((l) => l.id);
                    const coursesEngagement = await engagement.getCourses(courseIds);
                    const lessonsEngagement = await engagement.getLessons(lessonIds);
                    result = {
                        ...result,
                        lessons: searchResult.lessons.map(l => applyLessonEngagement(l, lessonsEngagement.lessonsEngagement)),
                        courses: searchResult.courses.map(c => applyCourseEngagement(c, coursesEngagement.coursesEngagement)),
                    };
                    subscriber.next(result);
                }),
            ]);
        }).catch(e => {
            subscriber.error(e);
        });
    });
};
const applyLessonEngagement = (item, engagement) => {
    return {
        ...item,
        contentType: 'media',
        likeState: engagement && engagement[item.id] ? engagement[item.id].likeState : 'none',
        progress: engagement && engagement[item.id] ? engagement[item.id].progress : 0,
        myLearnnId: engagement && engagement[item.id] ? engagement[item.id].myLearnnId : undefined,
    };
};
const applyCourseEngagement = (item, engagement) => {
    return {
        ...item,
        resources: null,
        duration: `${item.totalHours ? item.totalHours + 'h' : ''} ${item.totalMinutes ? item.totalMinutes + 'min' : ''}`,
        progress: engagement && engagement[item.id] ? engagement[item.id].progress : undefined,
        myLearnnId: engagement && engagement[item.id] ? engagement[item.id].myLearnnId : undefined,
    };
};
