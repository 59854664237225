import * as TE from 'fp-ts/TaskEither';
import { gql } from '@apollo/client';
import { pathDtoConverter } from './types';
import { DEFAULT_WALL_LIMIT, normalizeGraphqlResponse } from '../utils';
export const getPath = ({ client }) => slug => {
    return TE.tryCatch(() => (async () => {
        const result = await client
            .query({
            query: GET_PATH,
            variables: { slug: slug },
        })
            .then(normalizeGraphqlResponse);
        const paths = result.data?.paths;
        if (!paths || (paths && paths.length === 0)) {
            throw new Error(`Path ${slug} not found`);
        }
        const path = pathDtoConverter(paths[0]);
        return path;
    })(), r => {
        return new Error(String(r));
    });
};
export const getPathById = ({ client }) => async (id) => {
    const result = await client
        .query({
        query: GET_PATH_BY_ID,
        variables: { id: id },
    })
        .then(normalizeGraphqlResponse);
    const pathResult = result.data?.path;
    if (!pathResult) {
        throw new Error(`Path ${id} not found`);
    }
    const path = pathDtoConverter(pathResult);
    return path;
};
export const PATH_GRAPH = `
id
attributes {
  slug
  title
  summary
  description
  createdAt
  image {
    data {
     id
      attributes {
        formats
        urlCdn
      }
    }
  }
  courses(pagination: {pageSize: ${DEFAULT_WALL_LIMIT}}) {
    title
    subtitle
    course {
      data {
        id
        attributes {
          type {
            data {
              attributes {
                title
                slug
              }
            }
          }
          title
          coverImage {
            data {
              id
              attributes {
                formats
                urlCdn
              }
            }
          }
          totalHours
          totalMinutes
          lessonsCount
          authors {
            data {
              attributes {
                firstName
                lastName
              }
            }
          }
        }
      }
    }
  }
}
`;
const GET_PATH = gql `
  query getPath($slug: String!) {
    paths(filters: { slug: { eq: $slug } }) {
      data {
        ${PATH_GRAPH}
      }
    }
  }
`;
const GET_PATH_BY_ID = gql `
  query getPath($id: ID!) {
    path(id: $id) {
      data {
        ${PATH_GRAPH}
      }
    }
  }
`;
